import React from "react";
import "./CandidateDetails.css";
import { Link } from "react-router-dom";
import CustomButton from "../customButton/CustomButton";
import { ImCancelCircle } from "react-icons/im";
import { useGetApplicantQuery } from "../../store/query/applicants";
import { useTypedSelector } from "../../hook/useTypedSelector";
import { Bars } from "react-loader-spinner";
import { config } from "../../config";
import moment from "moment";

const CandidateDetails = ({ close, id }) => {
  const { user } = useTypedSelector((store) => store.appUser);
  const { data, isLoading } = useGetApplicantQuery({
    type: user.account_type,
    id: id,
  });

  const profilePix = data?.data?.profile_pix
    ? `${config.getPublicImages}/${data?.data?.profile_pix}`
    : "https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_640.png";

  const file = data?.data?.cv
    ? `${config.getPublicImages}/${data?.data?.cv}`
    : null;

  const license = data?.data?.License
    ? `${config.getPublicImages}/${data?.data?.license}`
    : null;

  if (isLoading) {
    return (
      <div className="pag-screen">
        <Bars
          height="80"
          width="80"
          color=" #245293"
          ariaLabel="bars-loading"
          wrapperStyle={{}}
          wrapperClass=""
          visible={true}
        />
      </div>
    );
  }

  return (
    <div className="singleApplicantPageContainer">
      <ImCancelCircle className="icon-can" onClick={close} />
      <div className="single-applicant-page-content">
        <div className="page-content-top">
          <div className="top-image">
            <img src={profilePix} alt="profile" />
          </div>

          <div className="top-name">
            <h3>
              {data?.data?.name.charAt(0).toUpperCase() +
                data?.data?.name.slice(1)}
            </h3>

            <span className="status verified">verified</span>
          </div>

          <div className="top-email">
            <p>{data?.data?.email}</p>
            <div className="dot">
              <p>.</p>
            </div>
            <p>{data?.data?.phone}</p>

            <div className="dot">
              <p>.</p>
            </div>

            <p>{data?.data?.division?.name}</p>

            <div className="dot">
              <p>.</p>
            </div>

            <p>
              {data?.data?.address}, {data?.data?.city}
            </p>
          </div>
        </div>
        <div className="page-content-bottom">
          <div className="content">
            <div className="box wider">
              <div className="upper">
                <div className="upper-inner space">
                  <h6>Location</h6>

                  <p>
                    {data?.data?.state}, {data?.data?.country}
                  </p>
                </div>

                <div className="space">
                  <h6>Date of birth</h6>

                  <p>{moment(data?.data?.date_of_birth).format("LL")}</p>
                </div>
              </div>
              <div className="middle">
                <div className="space-2">
                  <h6>Skills</h6>

                  <div className="loc-states">
                    {data?.data?.skills?.map((skill) => (
                      <div key={skill} className="loc-states-each">
                        <div className="main">{skill}</div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>
            <div className="box smaller">
              <h6>Attachement</h6>

              <div className="inner-box">
                <>
                  <Link
                    to={file && file}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CustomButton
                      variant="outline"
                      customStyles={btnStyled}
                      title="View CV"
                      border="#d8dde5"
                    />
                  </Link>

                  <Link
                    to={license && license}
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CustomButton
                      variant="outline"
                      customStyles={btnStyled}
                      title="View License"
                      border="#d8dde5"
                    />
                  </Link>
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CandidateDetails;

const btnStyled = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: "100%",
  padding: "2px 8px",
  borderRadius: "0px",
  BsBorderWidth: "1px",
  fontSize: "13px",
  height: "30px",
  marginTop: "0px",
};

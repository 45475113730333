import React, { useState } from "react";
import "./Footer.css";
import Logo from "../../assets/Group.png";
import appStore from "../../assets/appstore.svg";
import playStore from "../../assets/playstore.svg";
import CustomToolTips from "../toolTips/CustomToolTips";
import { Link } from "react-router-dom";

const Footer = () => {
  const [appClick, setAppClick] = useState(false);
  const [playClick, setPlayClick] = useState(false);

  return (
    <div className="footer-container">
      <div className="footer-box-left">
        <div className="footer-logo-header">
          <div className="footer-logo">
            <img src={Logo} alt="footerlogo" />
          </div>

          <Link to="/terms" className="footer-sec">
            <span>Terms and conditions</span>
          </Link>
        </div>
        <span className="footer-rights">
          © 2023 Freelancemedic. All rights reserved
        </span>
      </div>
      <div className="footer-box-right">
        <span>Download App</span>
        <div className="flex-contact">
          <CustomToolTips
            title="Coming soon"
            open={appClick}
            placement="left-start"
          >
            <div
              className="foot-logo-btn"
              onClick={() => setAppClick(!appClick)}
            >
              <img src={appStore} alt="foot-img" />
            </div>
          </CustomToolTips>

          <CustomToolTips
            title="Coming soon"
            open={playClick}
            placement="right-start"
          >
            <div
              className="foot-logo-btn"
              onClick={() => setPlayClick(!playClick)}
            >
              <img src={playStore} alt="foot-img" />
            </div>
          </CustomToolTips>
        </div>
      </div>
    </div>
  );
};

export default Footer;

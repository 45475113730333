import React, { useState } from "react";
import "./SignUp.css";
import Logo from "../../../assets/fmlogo-login.png";
import Doctor from "../../../assets/doc-2.png";
import { Link } from "react-router-dom";
import whiteBox from "../../../assets/white-box.png";
import Logo2 from "../../../assets/Group.png";
import CustomInput from "./CustomInput";
import DashboardModal from "../../../components/dashboardModal/DashboardModal";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { toast } from "react-toastify";
import ReCAPTCHA from "react-google-recaptcha";
import { config } from "../../../config";
import CustomButton from "../../../components/customButton/CustomButton";
import CustomPhoneInput from "../../../components/customPhoneInput/CustomPhoneInput";
import CustomDialog from "../../../components/customDialog/CustomDialog";
import {
  useEmailValidationMutation,
  useEmailVerifyMutation,
  useSendOTPMutation,
} from "../../../store/query/auth";
import { Bars } from "react-loader-spinner";
import { MdDone } from "react-icons/md";

const SignUp = ({ formik, onNext }) => {
  const [capVal, setCapVal] = useState();
  const [modalTerms, setModalTerms] = useState(false);
  const [passwordEye, setPasswordEye] = useState(false);
  const [conPasswordEye, setConPasswordEye] = useState(false);
  const [tnc, setTnc] = useState(false);
  const [isOTP, setIsOTP] = useState(false);
  const { values, handleChange, errors, isValid, touched, handleBlur } = formik;
  const [
    emailVerify,
    { isLoading: isVerifingEmail, error: emailError, isSuccess: emailSuccess },
  ] = useEmailVerifyMutation();
  console.log(emailError?.data?.message);
  const [sendOTP, { isLoading: isSendingOTP }] = useSendOTPMutation();

  const formSubmit = async (e) => {
    e.preventDefault();
    if (tnc && isValid && capVal && emailSuccess) {
      const res = await sendOTP({ email: values.email });
      if (res.data.status) {
        toast.success(res.data.message);
        setIsOTP(true);
      } else {
        toast.error(res.data.message);
      }
    } else {
      return toast.error("Please fill all inputs and check box", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "dark",
      });
    }
  };

  const styles = {
    eye: {
      position: "absolute",
      top: "9.5px",
      right: "10px",
      fontSize: "22px",
      cursor: "pointer",
    },
  };

  return (
    <div className="signUp-container">
      <div className="signUp-left">
        <div className="signUp-left-content">
          <Link to="/">
            <div className="logo">
              <img src={Logo} alt="logo" />
            </div>
          </Link>

          <div className="signUp-left-text">
            <h3>
              A community-driven healthcare workforce marketplace designed for
              flexible and rewarding practice.
            </h3>
          </div>
          <div className="signUp-left-image">
            <img className="white-box" src={whiteBox} alt="" />
            <img className="doc" src={Doctor} alt="" />
          </div>
        </div>
      </div>

      <div className="signUp-right">
        <div className="signup-right-body">
          <div className="login-button-box">
            <Link to="/">
              <img src={Logo2} alt="logo" />
            </Link>

            <Link className="login-btn-link" to="/login">
              <CustomButton
                customStyles={{
                  width: "85px",
                  height: "40px",
                  marginTop: "0",
                  fontSize: "15px",
                  borderRadius: "5px",
                }}
                title="Login"
              />
            </Link>
          </div>

          <div className="signUp-right-content">
            {/* form starts */}
            <form onSubmit={formSubmit}>
              <div className="form-label">
                <CustomInput
                  id="name"
                  label="Full Name *"
                  placeholder="Please enter your name"
                  type="text"
                  value={values.name}
                  onChange={handleChange("name")}
                  touched={touched.name}
                  error={errors.name}
                  onBlur={(e) => handleBlur(e)}
                />

                <CustomInput
                  id="email"
                  label="Email Address *"
                  placeholder="Enter your email address"
                  type="email"
                  value={values.email}
                  onChange={handleChange("email")}
                  touched={touched.email}
                  error={
                    errors.email ??
                    emailError?.data?.errors?.email[0] ??
                    emailError?.data?.message
                  }
                  onBlur={async (e) => {
                    handleBlur(e);
                    await emailVerify({ email: values.email });
                  }}
                  endIcon={
                    <div style={{ position: "absolute", top: 14, right: 20 }}>
                      {isVerifingEmail && (
                        <Bars
                          height="12"
                          width="12"
                          color=" #245293"
                          ariaLabel="bars-loading"
                          wrapperStyle={{}}
                          wrapperClass=""
                          visible={true}
                        />
                      )}
                      {emailSuccess && (
                        <MdDone height="12" width="12" color=" #245293" />
                      )}
                    </div>
                  }
                />

                <CustomPhoneInput
                  id="phone"
                  label="Phone Number *"
                  value={values.phone}
                  onChange={handleChange("phone")}
                  touched={touched.phone}
                  error={errors.phone}
                  onBlur={(e) => handleBlur(e)}
                  customStyle={{
                    label: {
                      color: "black",
                      fontSize: "14px",
                      fontWeight: 500,
                    },
                  }}
                />

                <CustomInput
                  id="password"
                  label="Password *"
                  placeholder="Enter your Password"
                  type={passwordEye ? "text" : "password"}
                  value={values.password}
                  onChange={handleChange("password")}
                  touched={touched.password}
                  error={errors.password}
                  onBlur={(e) => handleBlur(e)}
                  endIcon={
                    passwordEye ? (
                      <AiOutlineEye
                        style={styles.eye}
                        onClick={() => setPasswordEye(!passwordEye)}
                      />
                    ) : (
                      <AiOutlineEyeInvisible
                        style={styles.eye}
                        onClick={() => setPasswordEye(!passwordEye)}
                      />
                    )
                  }
                />

                <CustomInput
                  id="confirm_password"
                  label="Confirm Password *"
                  placeholder="Confirm your Password"
                  type={conPasswordEye ? "text" : "password"}
                  value={values.confirm_password}
                  onChange={handleChange("confirm_password")}
                  touched={touched.confirm_password}
                  error={errors.confirm_password}
                  onBlur={(e) => handleBlur(e)}
                  endIcon={
                    conPasswordEye ? (
                      <AiOutlineEye
                        style={styles.eye}
                        onClick={() => setConPasswordEye(!conPasswordEye)}
                      />
                    ) : (
                      <AiOutlineEyeInvisible
                        style={styles.eye}
                        onClick={() => setConPasswordEye(!conPasswordEye)}
                      />
                    )
                  }
                />
              </div>

              <ReCAPTCHA
                sitekey={config.recaptchaSiteKey}
                onChange={(val) => setCapVal(val)}
              />

              <div className="terms">
                <input
                  id="check-box"
                  type="checkbox"
                  value={tnc}
                  onChange={() => setTnc(!tnc)}
                />
                <span>
                  By clicking this checkbox, I hereby certify that I have read
                  and agree to the{" "}
                  <span
                    className="terms-modal"
                    onClick={() => setModalTerms(true)}
                  >
                    terms and conditions
                  </span>{" "}
                  stated therein
                </span>
              </div>

              <div className="bg-signup">
                <CustomButton
                  disable={!isValid || isSendingOTP}
                  loading={isSendingOTP}
                  type="submit"
                  title="Proceed"
                />
              </div>
            </form>
          </div>
        </div>
      </div>

      <DashboardModal
        isOpen={modalTerms}
        onClose={() => setModalTerms(false)}
        customStyle={{
          overflowX: "hidden",
          height: "600px",
        }}
        children={
          <div className="signup-modal">
            <h4>
              USER AGREEMENT FOR FREELANCEMEDIC HEALTHCARE PERSONNEL LOCUM
              HIRING PLATFORM{" "}
            </h4>
            <p className="head">1. Introduction </p>
            <p>
              1.1 Agreement Acceptance: This User Agreement ("Agreement") is a
              legally binding contract between FreelanceMedic and the user
              ("User") of the FreelanceMedic healthcare personnel locum hiring
              platform. By accessing or using any of its platforms (Website,
              Mobile Applications, etcetera), the User agrees to be bound by the
              terms and conditions set forth in this Agreement.
            </p>{" "}
            <p>
              1.2 Parties to the Agreement: This Agreement is entered into
              between FreelanceMedic, a business duly registered with the
              Corporate Affairs Commission of Nigeria as well as operating under
              the laws of Nigeria, and the User, an individual or entity seeking
              to utilize the services provided by FreelanceMedic.
            </p>{" "}
            <p>
              1.3 Platform Description and Purpose: FreelanceMedic is an online
              platform that connects healthcare personnel seeking locum
              opportunities with medical facilities(Hospitals, Pharmacies,
              Clinics, Laboratories, Etcetera) in need of temporary staffing.
              The platform facilitates the hiring process/onboarding, providing
              a centralized marketplace for healthcare personnels and medical
              facilities to engage in locum work arrangements.
            </p>{" "}
            <p>
              1.4 Definitions and Interpretation: This section provides
              definitions for key terms used throughout the Agreement to ensure
              clarity and understanding between the parties. It clarifies the
              interpretation of terms used in the context of this Agreement.{" "}
            </p>
            <p className="head">2. User Eligibility and Registration</p>
            <p>
              2.1 User Eligibility Requirements: Users must meet the eligibility
              criteria specified by FreelanceMedic to access and use the
              platform. This includes being a licensed healthcare professional
              in Nigeria or any other country FreelanceMedic might extend its
              services as applicable and complying with any additional
              requirements set by FreelanceMedic.
            </p>{" "}
            <p>
              2.2 Registration Process: Users must complete the registration
              process to create an account on FreelanceMedic. The registration
              requires providing accurate and up-to-date personal, professional
              and organizational information (where applicable), and Users agree
              to maintain the accuracy of their account information.
            </p>
            <p>
              2.3 User Account Responsibilities: Users are responsible for
              safeguarding their account credentials and ensuring the
              confidentiality of their account information. Users agree to be
              solely responsible for any activity conducted through their
              accounts.
            </p>{" "}
            <p>
              2.4 Account Security and Confidentiality: FreelanceMedic will
              implement reasonable security measures to protect User accounts
              and information. Users are advised to exercise caution in sharing
              their account information and report any suspected unauthorized
              access or security breaches.
            </p>{" "}
            <p>
              2.5 Account Termination and Suspension: FreelanceMedic reserves
              the right to suspend or terminate User accounts for violation of
              this Agreement, non-compliance with applicable laws, or any other
              reason deemed necessary to protect the integrity and security of
              the platform.
            </p>
            <p className="head">3. Services and Obligations</p>
            <p>
              3.1 Platform Services Overview: FreelanceMedic provides a platform
              that enables Users to search for locum opportunities, submit
              applications, communicate with medical facilities through its
              representative, and enter locum work arrangements. The platform
              also allows users to create locum job openings within their
              profession as well as offers additional features and services to
              facilitate a smooth hiring process.
            </p>{" "}
            <p>
              3.2 User Responsibilities and Obligations: Users agree to comply
              with all applicable laws, regulations, and professional standards
              while using the platform. Users are responsible for providing
              accurate and complete information, ensuring the legality and
              quality of their services, and maintaining professionalism in
              their interactions with medical facilities as well as peers.
            </p>{" "}
            <p>
              3.3 Compliance with Laws and Regulations: Users must adhere to all
              Nigerian laws, regulations, and guidelines relevant to their
              professional practice and the provision of healthcare services.
              Users must obtain and maintain any necessary licenses,
              certifications, and permissions required by law. This also applies
              to Countries where FreelanceMedic might extend its services.
            </p>{" "}
            <p>
              3.4 Availability and Modifications to Services: FreelanceMedic
              strives to provide uninterrupted access to its platform but does
              not guarantee its availability at all times. The platform may
              undergo maintenance or updates, and FreelanceMedic reserves the
              right to modify or discontinue any feature or service without
              prior notice.
            </p>
            <p>
              3.5 Third-Party Services and Links: FreelanceMedic may integrate
              third-party services or include links to external websites. Users
              acknowledge that these third-party services are subject to their
              own terms and conditions, and FreelanceMedic does not endorse or
              assume responsibility for their content or practices.
            </p>
            <p className="head">4. Locum Hiring Process</p>
            <p>
              4.1 Job Listings and Job Application: FreelanceMedic allows
              medical facilities and registered healthcare personnels to post
              locum job listings, and Users can submit applications based on
              their qualifications and availability. Users understand that
              FreelanceMedic does not guarantee job placement and that hiring
              decisions are the sole discretion of the medical facilities or
              individual representative.
            </p>{" "}
            <p>
              4.2 Verification and Credentialing: FreelanceMedic may verify User
              credentials, qualifications, and licenses to ensure the accuracy
              and legitimacy of information provided. Users consent to the
              verification process and agree to cooperate with FreelanceMedic in
              providing necessary documentation.
            </p>{" "}
            <p>
              4.3 Selection and Confirmation of Locum: Medical facilities have
              the right to select a suitable candidate for locum work based on
              their own criteria. If selected, Users agree to confirm their
              availability and comply with the agreed-upon terms and conditions
              for the locum assignment.
            </p>{" "}
            <p>
              4.4 Locum Cancellation and Rescheduling: Users and medical
              facilities may cancel or reschedule locum assignments under
              agreed-upon terms. Users agree to provide reasonable notice and
              cooperate in finding suitable alternatives when necessary.
            </p>{" "}
            <p>
              4.5 Payment and Billing Process: FreelanceMedic facilitates the
              payment process between Users and medical facilities. The specific
              payment terms, rates, and invoicing procedures will be agreed upon
              between the Users and the medical facilities. FreelanceMedic may
              charge a service fee or commission based on the successful
              completion of a locum assignment.
            </p>{" "}
            <p>
              4.6 Feedback and Ratings Users may provide feedback and ratings on
              their locum assignments and their overall experience with medical
              facilities. FreelanceMedic may display this feedback on the
              platform to assist other Users in making informed decisions.
            </p>
            <p className="head">5. User Content and Intellectual Property</p>
            <p>
              5.1 User Content Ownership and Rights: Users retain ownership of
              the content they submit to the platform, such as profile
              information, credentials, and communication with medical
              facilities. By submitting content, Users grant FreelanceMedic a
              non-exclusive, royalty-free license to use, reproduce, and display
              the content as necessary for platform operation and promotion.
            </p>
            <p>
              5.2 Platform Content Ownership and Rights: FreelanceMedic retains
              all ownership rights to the platform's content, including text,
              graphics, logos, trademarks, and software. Users may not modify,
              reproduce, distribute, or exploit the platform's content without
              obtaining explicit permission from FreelanceMedic.
            </p>{" "}
            <p>
              5.3 Prohibited Content and Activities: Users agree not to upload,
              publish, or transmit any content that is unlawful, fraudulent,
              misleading, defamatory, or violates the rights of others. Users
              must also refrain from engaging in any activities that could
              compromise the platform's security, integrity, or performance.
            </p>{" "}
            <p>
              5.4 Copyright Infringement Claims: FreelanceMedic respects the
              intellectual property rights of others and expects Users to do the
              same. If Users believe their copyrighted work has been infringed
              upon, they may submit a notice of infringement to FreelanceMedic,
              who will take appropriate action as required by Nigerian law.
            </p>
            <p className="head">6. Privacy and Data Protection</p>
            <p>
              6.1 Data Collection and Use: FreelanceMedic collects and processes
              User data in accordance with applicable Nigerian data protection
              laws. Users acknowledge and consent to the collection, use, and
              processing of their personal and professional information by
              FreelanceMedic for the purposes outlined in the Privacy Policy.
            </p>{" "}
            <p>
              6.2 Data Sharing and Third-Party Access FreelanceMedic may share
              User data with third parties, including medical facilities, for
              the purpose of facilitating locum work arrangements. User data
              will be shared only to the extent necessary and in compliance with
              applicable data protection laws. For Nigeria, see Data Protection
              Laws and Regulations Report 2023 Nigeria (iclg.com).
            </p>{" "}
            <p>
              6.3 Data Security Measures: FreelanceMedic will implement
              reasonable security measures to protect User data from
              unauthorized access, disclosure, alteration, or destruction.
              However, Users acknowledge that no data transmission over the
              internet or storage system can be guaranteed to be 100% secure.
            </p>{" "}
            <p>
              6.4 User Communication and Notifications: FreelanceMedic may
              communicate with Users through various channels, including email,
              notifications within the platform, or other electronic means.
              Users agree to receive these communications and acknowledge that
              they may contain important information regarding their account,
              locum assignments, or platform updates.
            </p>{" "}
            <p>
              6.5 Cookies and Tracking Technologies: FreelanceMedic may use
              cookies and tracking technologies to enhance User experience,
              analyze usage patterns, and personalize content. By using the
              platform, Users consent to the use of such technologies in
              accordance with the Cookie Policy.
            </p>
            <p className="head">7. Confidentiality and Non-Disclosure</p>
            <p>
              7.1 Confidential Information Definition The parties recognize that
              during the use of the platform, they may have access to
              confidential and proprietary information. "Confidential
              Information" includes, but is not limited to, user data, business
              strategies, pricing information, and trade secrets.
            </p>{" "}
            <p>
              7.2 Obligations to Protect Confidential Information: Users agree
              to maintain the confidentiality of any Confidential Information
              they receive from FreelanceMedic or other Users. Users shall not
              disclose, reproduce, or use Confidential Information for any
              purpose other than fulfilling their obligations under this
              Agreement, unless required by law.
            </p>{" "}
            <p>
              7.3 Exceptions to Confidentiality: Users' obligations of
              confidentiality shall not apply to information that: (a) is
              publicly available or becomes publicly available without breach of
              this Agreement; (b) is rightfully obtained from a third party
              without obligations of confidentiality; (c) is independently
              developed by the receiving party without reference to the
              Confidential Information; or (d) is required to be disclosed by
              applicable law or court order.
            </p>
            <p className="head">8. Limitation of Liability</p>
            <p>
              8.1 Disclaimer of Warranties: FreelanceMedic provides the platform
              "as is" and does not make any warranties or representations
              regarding its accuracy, reliability, or suitability for any
              particular purpose. Users acknowledge that the use of the platform
              is at their own risk.
            </p>{" "}
            <p>
              8.2 Limitation of Liability: To the extent permitted by Nigerian
              law, FreelanceMedic shall not be liable for any direct, indirect,
              incidental, consequential, or punitive damages arising out of or
              in connection with the use of the platform, even if FreelanceMedic
              has been advised of the possibility of such damages.
            </p>{" "}
            <p>
              8.3 Indemnification: Users agree to indemnify and hold
              FreelanceMedic harmless from any claims, damages, losses,
              liabilities, and expenses (including legal fees) arising out of or
              in connection with their use of the platform, violation of this
              Agreement, or infringement of any rights of third parties.
            </p>
            <p className="head">9. Dispute Resolution and Governing Law</p>
            <p>
              9.1 Dispute Resolution Process: Any disputes arising out of or
              relating to this Agreement shall be resolved amicably through
              good-faith negotiations between the parties. If a resolution
              cannot be reached, the parties agree to submit the dispute to
              arbitration in accordance with the arbitration rules of Nigeria.
            </p>
            <p>
              9.2 Governing Law and Jurisdiction: This Agreement shall be
              governed by and construed in accordance with the laws of Nigeria.
              The parties agree that any legal action or proceeding arising out
              of or relating to this Agreement shall be brought exclusively in
              the courts of Nigeria.
            </p>
            <p className="head">10. Termination and Suspension</p>
            <p>
              10.1 Termination by Users: Users may terminate their account and
              this Agreement by providing written notice to FreelanceMedic.
              Termination shall not relieve Users of any obligations or
              liabilities incurred prior to the termination date.
            </p>{" "}
            <p>
              10.2 Termination by the Platform: FreelanceMedic reserves the
              right to terminate User accounts and this Agreement at its
              discretion, without prior notice, in the event of a breach of this
              Agreement, violation of applicable laws, or any conduct deemed
              detrimental to the platform or other Users.
            </p>{" "}
            <p>
              10.3 Consequences of Termination: Upon termination, Users' access
              to the platform and associated services will cease. Users shall
              remain liable for any outstanding obligations or liabilities
              accrued prior to termination.
            </p>
            <p className="head">11. General Provisions</p>{" "}
            <p>
              11.1 Entire Agreement: This Agreement constitutes the entire
              agreement between FreelanceMedic and the User regarding the use of
              the platform and supersedes any prior agreements or
              understandings, whether written or oral.
            </p>{" "}
            <p>
              11.2 Amendments and Modifications: FreelanceMedic may modify or
              update this Agreement from time to time. Users will be notified of
              any material changes, and continued use of the platform after such
              changes shall constitute acceptance of the modified Agreement.
            </p>{" "}
            <p>
              11.3 Severability: If any provision of this Agreement is found to
              be invalid, illegal, or unenforceable, the remaining provisions
              shall continue in full force and effect, and the invalid provision
              shall be replaced with a valid provision that achieves the
              intended purpose to the maximum extent possible.
            </p>{" "}
            <p>
              11.4 Waiver: The failure of FreelanceMedic to enforce any
              provision of this Agreement shall not be deemed a waiver of its
              right to enforce such provision in the future.
            </p>{" "}
            <p>
              11.5 Relationship of the Parties: Nothing in this Agreement shall
              be construed as creating a partnership, joint venture, agency, or
              employment relationship between FreelanceMedic and the User. Users
              shall act as independent contractors.
            </p>
            <p>
              11.6 Assignment: Users may not assign or transfer their rights or
              obligations under this Agreement without the prior written consent
              of FreelanceMedic. FreelanceMedic may assign or transfer its
              rights or obligations without User consent.
            </p>{" "}
            <p>
              11.7 Force Majeure: Neither party shall be liable for any failure
              or delay in the performance of its obligations under this
              Agreement due to circumstances beyond its reasonable control,
              including acts of God, natural disasters, wars, or governmental
              actions.
            </p>{" "}
            <p>
              11.8 Notices: All notices and communications regarding this
              Agreement shall be in writing and delivered via email or through
              the platform's messaging system. Notices to FreelanceMedic shall
              be sent to the designated contact information provided by the
              platform.
            </p>{" "}
            <p>
              11.9 Survival of Terms: Any provisions of this Agreement that, by
              their nature, should survive termination or expiration, including
              but not limited to confidentiality obligations, limitation of
              liability, and dispute resolution provisions, shall continue to
              apply.
            </p>{" "}
            <p>
              11.10 Interpretation: The headings in this Agreement are for
              convenience purposes only and shall not affect the interpretation
              or construction of the provisions herein.
            </p>{" "}
            <p>
              11.11 Language: This Agreement is drafted in the English language.
              If any translation of this Agreement is provided, the English
              version shall prevail in case of any inconsistencies or disputes.
            </p>
            <p>
              11.12 Electronic Signature: This Agreement may be executed
              electronically, and electronic signatures shall have the same
              legal effect as handwritten signatures.
            </p>{" "}
            <p>
              11.13 Contact Information: Users may contact FreelanceMedic at the
              designated contact information provided on the platform for any
              questions, concerns, or notices related to this Agreement.
            </p>
          </div>
        }
      />

      <CustomDialog onClose={() => setIsOTP(false)} open={isOTP}>
        <OTPmodal email={values.email} onNext={onNext} />
      </CustomDialog>
    </div>
  );
};

export default SignUp;

const OTPmodal = ({ email, onNext }) => {
  const [otp, setOtp] = useState(new Array(4).fill(""));
  const [emailValidation, { isLoading: isValidatingEmail }] =
    useEmailValidationMutation();

  const handleChange = (e, index) => {
    setOtp([...otp.map((data, i) => (i === index ? e.target.value : data))]);
    if (e.target.value && e.target.nextSibling) {
      e.target.nextSibling.focus();
    }
  };

  const onValidate = async () => {
    const res = await emailValidation({ email: email, otp: otp.join("") });
    if (res.data.status) {
      toast.success(res.data.message);
      onNext();
    } else {
      toast.error(res.data.message);
    }
  };

  return (
    <div className="otp-bg">
      <h6>Kindly Provide OTP Sent To Your Email.</h6>

      <div className="otp-input-bg">
        {otp.map((data, i) => (
          <input
            type="text"
            value={data}
            maxLength={1}
            onChange={(e) => handleChange(e, i)}
          />
        ))}
      </div>

      <center className="otp-btn-bg">
        <CustomButton
          customStyles={{
            width: "150px",
            height: "40px",
            marginTop: "0",
            fontSize: "15px",
            borderRadius: "5px",
          }}
          title="Verify"
          loading={isValidatingEmail}
          disable={isValidatingEmail}
          onClick={onValidate}
        />
      </center>
    </div>
  );
};

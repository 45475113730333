import React, { useState } from "react";
import "./Payment.css";
import PaymentCard from "./components/paymentCard/PaymentCard";
import PaymentSection from "./components/paymentSection/PaymentSection";
import CustomDialog from "../../../components/customDialog/CustomDialog";
import ModalHeader from "./components/modalHeader/ModalHeader";
// import ModalLaterBtn from "./components/modalLaterBtn/ModalLaterBtn";
// import AddMoneyNew from "./components/addMoney/AddMoneyNew";
// import AddBankCard from "./components/addMoney/AddBankCard";
// import AddBankCardOTP from "./components/addMoney/AddBankCardOTP";
// import AddMoneyPassword from "./components/addMoney/AddMoneyPassword";
// import AddMoneyAllCards from "./components/addMoney/AddMoneyAllCards";
// import Withdrawal from "./components/withdrawal/Withdrawal";
// import WithdrawalOTP from "./components/withdrawal/WithdrawalOTP";
// import WithdrawalSuccess from "./components/withdrawal/WithdrawalSuccess";
import { paymentHistory } from "../../../assets/userData";
import CustomAutoComplete from "../../../components/customAutoComplete/CustomAutoComplete";
import ProfileEditInput from "../../../components/profileEditInput/ProfileEditInput";
import usePayments from "../../../hook/usePayments";
import { useTypedSelector } from "../../../hook/useTypedSelector";
import {
  useAddBankAccountMutation,
  useGetAccountQuery,
  useLazyVerifyAccountQuery,
  useUpdateBankAccountMutation,
} from "../../../store/query/payments";
import CustomButton from "../../../components/customButton/CustomButton";
import { toast } from "react-toastify";

const Payment = () => {
  const [showSetup, setShowSetup] = useState(false);
  const { user } = useTypedSelector((store) => store.appUser);
  // const [showAddMoney, setShowAddMoney] = useState(false);
  // const [showWithdraw, setShowWithdraw] = useState(false);
  // const [stepAddMoney, setStepAddMoney] = useState(1);
  // const [stepWithdraw, setStepWithdraw] = useState(1);
  const {
    data: account,
    isLoading: isAccounting,
    refetch,
  } = useGetAccountQuery({
    acc_type: user?.account_type,
  });
  console.log(account);

  const handleClose = () => {
    //setShowAddMoney(false);
    setShowSetup(false);
    refetch();
    // setShowWithdraw(false);
    // setStepAddMoney(1);
    // setStepWithdraw(1);
  };

  // const addMoneyModal = () => {
  //   switch (stepAddMoney) {
  //     case 1:
  //       return (
  //         <AddMoneyNew
  //           handleClose={handleClose}
  //           setStepAddMoney={setStepAddMoney}
  //         />
  //       );
  //     case 2:
  //       return (
  //         <AddBankCard
  //           handleClose={handleClose}
  //           setStepAddMoney={setStepAddMoney}
  //         />
  //       );
  //     case 3:
  //       return (
  //         <AddBankCardOTP
  //           handleClose={handleClose}
  //           setStepAddMoney={setStepAddMoney}
  //         />
  //       );
  //     case 4:
  //       return (
  //         <AddMoneyPassword
  //           handleClose={handleClose}
  //           setStepAddMoney={setStepAddMoney}
  //         />
  //       );
  //     case 5:
  //       return (
  //         <AddMoneyAllCards
  //           handleClose={handleClose}
  //           setStepAddMoney={setStepAddMoney}
  //         />
  //       );
  //   }
  // };

  // const withdrawModal = () => {
  //   switch (stepWithdraw) {
  //     case 1:
  //       return (
  //         <Withdrawal
  //           handleClose={handleClose}
  //           setStepWithdraw={setStepWithdraw}
  //         />
  //       );
  //     case 2:
  //       return (
  //         <WithdrawalOTP
  //           handleClose={handleClose}
  //           setStepWithdraw={setStepWithdraw}
  //         />
  //       );
  //     case 3:
  //       return (
  //         <WithdrawalSuccess
  //           handleClose={handleClose}
  //           setStepWithdraw={setStepWithdraw}
  //         />
  //       );
  //   }
  // };

  return (
    <div className="payment-container">
      <h2>Payment</h2>

      <div className="payment-content">
        <div className="box-container">
          <PaymentCard label="Total Payout" amount={"NGN 540,000"} />
          {user?.account_type === "organisation" ? (
            <PaymentCard label="Pending Refund" amount={"NGN 24,000"} />
          ) : (
            <PaymentCard label="Pending Payment" amount={"NGN 24,000"} />
          )}
        </div>

        <div>
          {/* <PaymentSection
            subTitle="where your earning would be credited into"
            btnTitle={"Add account"}
            showmodal={() => setShowSetup(true)}
          /> */}

          <PaymentSection
            accNo={
              account?.data ? account?.data?.account_number : "ADD ACCOUNT"
            }
            subTitle={account?.data && account?.data?.bank_name}
            btnTitle={
              account?.data?.account_number ? "Edit Account" : "Add Account"
            }
            showmodal={() => setShowSetup(true)}
            loading={isAccounting}
          />
        </div>

        <div className="payment-bottom">
          <h4>Received Payment History</h4>

          <div className="table-container">
            <table className="table">
              <thead>
                <tr>
                  <th>Amount</th>
                  <th>Date</th>
                  <th>Method</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {paymentHistory.map((i, index) => (
                  <tr key={index}>
                    <td>NGN {i.amount}</td>
                    <td>{i.date}</td>
                    <td>{i.method}</td>
                    <td>
                      <div className="status">
                        <span
                          className={
                            i.status === "Pending"
                              ? "pend"
                              : i.status === "Successful"
                              ? "succ"
                              : null
                          }
                        >
                          {i.status}
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="mobile-table-con">
            {paymentHistory.map((i, index) => (
              <div key={index} className="mobile-single-table">
                <div className="cols-mobile">
                  <h6>Amount</h6>
                  <p>NGN {i.amount}</p>
                </div>
                <div className="cols-mobile">
                  <h6>Date</h6>
                  <p>{i.date}</p>
                </div>
                <div className="cols-mobile">
                  <h6>Method</h6>
                  <p>{i.method}</p>
                </div>
                <div className="cols-mobile">
                  <h6>Status</h6>
                  <div className="status">
                    <span
                      className={
                        i.status === "Pending"
                          ? "pend"
                          : i.status === "Successful"
                          ? "succ"
                          : null
                      }
                    >
                      {i.status}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* <div className="payment-board">
            <p>No transactions recorded yet</p>
          </div> */}
        </div>

        <div className="payment-bottom">
          <h4>Payout History</h4>

          <div className="table-container">
            <table className="table">
              <thead>
                <tr>
                  <th>Amount</th>
                  <th>Date</th>
                  <th>Method</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {paymentHistory.map((i, index) => (
                  <tr key={index}>
                    <td>NGN {i.amount}</td>
                    <td>{i.date}</td>
                    <td>{i.method}</td>
                    <td>
                      <div className="status">
                        <span
                          className={
                            i.status === "Pending"
                              ? "pend"
                              : i.status === "Successful"
                              ? "succ"
                              : null
                          }
                        >
                          {i.status}
                        </span>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          <div className="mobile-table-con">
            {paymentHistory.map((i, index) => (
              <div key={index} className="mobile-single-table">
                <div className="cols-mobile">
                  <h6>Amount</h6>
                  <p>NGN {i.amount}</p>
                </div>
                <div className="cols-mobile">
                  <h6>Date</h6>
                  <p>{i.date}</p>
                </div>
                <div className="cols-mobile">
                  <h6>Method</h6>
                  <p>{i.method}</p>
                </div>
                <div className="cols-mobile">
                  <h6>Status</h6>
                  <div className="status">
                    <span
                      className={
                        i.status === "Pending"
                          ? "pend"
                          : i.status === "Successful"
                          ? "succ"
                          : null
                      }
                    >
                      {i.status}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>
          {/* <div className="payment-board">
            <p>No transactions recorded yet</p>
          </div> */}
        </div>

        <CustomDialog open={showSetup}>
          <BankAccount
            close={handleClose}
            update={!!account?.data?.account_number}
          />
        </CustomDialog>

        {/* <CustomDialog open={showAddMoney}>{addMoneyModal()}</CustomDialog>
        <CustomDialog open={showWithdraw}>{withdrawModal()}</CustomDialog> */}
      </div>
    </div>
  );
};

export default Payment;

const BankAccount = ({ close, update = false }) => {
  console.log("is", update);
  const { banks } = usePayments();
  const { user } = useTypedSelector((store) => store.appUser);
  const [verifyAccount, { isLoading: isVerifying }] =
    useLazyVerifyAccountQuery();
  const [addBankAccount, { isLoading: isAdding }] = useAddBankAccountMutation();
  const [updateBankAccount, { isLoading: isUpdating }] =
    useUpdateBankAccountMutation();
  const [addName, setAddName] = useState(false);
  const [details, setDetails] = useState({
    user_id: user?.id,
    account_name: "",
    account_number: "",
    bank_name: "",
    bank_code: null,
  });

  const handleVerifyAcc = async () => {
    const { data } = await verifyAccount({
      acc_no: Number(details.account_number),
      bank_code: Number(details.bank_code),
    });

    if (data.status) {
      setDetails((prev) => ({ ...prev, account_name: data.data.account_name }));
      setAddName(false);
      toast.success(data.message);
    } else {
      toast.error("Add Account Name");
      setAddName(true);
    }
  };

  const handleAddAcc = async () => {
    const res = await addBankAccount({
      ...details,
      acc_type: user?.account_type,
    });
    if (res?.data?.status) {
      close();
      toast.success(res.data.message);
    } else {
      toast.error("Failed to Add Account");
    }
  };

  const handleUpdateAcc = async () => {
    const res = await updateBankAccount({
      ...details,
      acc_type: user?.account_type,
      _method: "PUT",
    });
    if (res?.data?.status) {
      close();
      toast.success(res.data.message);
    } else {
      toast.error("Failed to Add Account");
    }
  };

  const disable =
    details?.account_number.length <= 7 || details?.bank_code === null;

  return (
    <div className="modal-container-inner">
      <ModalHeader title="Bank Account" onClose={close} />

      <div className="warning-acc">
        <p>
          Your account details must match your registered name on our website to ensure seamless transactions.
        </p>
      </div>

      <div className="input-modal-cover">
        {details?.account_name.length && !addName ? (
          <ProfileEditInput
            label="Account Name"
            widthCon="100%"
            width="100%"
            value={details.account_name}
            readOnly
            customLabelStyles={{
              color: "#021424",
              fontWeight: 600,
            }}
            customInputStyles={{
              height: 55,
              fontSize: 16,
              fontWeight: 500,
              borderRadius: "0px",
              textTransform: "uppercase",
              border: "1px solid green",
              backgroundColor: "rgb(222, 250, 222)",
              color: "green",
            }}
          />
        ) : null}
        {addName ? (
          <ProfileEditInput
            label="Account Name"
            placeholder="Account Name"
            widthCon="100%"
            width="100%"
            value={details.account_name}
            onChange={(e) => {
              setDetails((prev) => ({ ...prev, account_name: e.target.value }));
            }}
            customLabelStyles={{
              color: "#021424",
              fontWeight: 600,
            }}
            customInputStyles={{
              height: 55,
              fontSize: 16,
              fontWeight: 500,
              borderRadius: "0px",
              textTransform: "uppercase",
            }}
          />
        ) : null}
        <div className="auto-complete-con">
          <CustomAutoComplete
            isClearable={false}
            label="Bank name"
            placeholder="Bank name"
            customLabelStyles={{
              color: "#021424",
              fontWeight: 600,
            }}
            customStyles={{
              valueContainer: {
                paddingTop: "11px",
                paddingBottom: "11px",
              },
            }}
            options={banks}
            onChange={(e) =>
              setDetails((prev) => ({
                ...prev,
                bank_name: e.label,
                bank_code: e.value,
              }))
            }
          />
        </div>
        <ProfileEditInput
          label="Account number"
          placeholder="Account number"
          widthCon="100%"
          width="100%"
          type="number"
          value={details.account_number}
          customLabelStyles={{
            color: "#021424",
            fontWeight: 600,
          }}
          customInputStyles={{
            height: 55,
            fontSize: 16,
            fontWeight: 500,
            borderRadius: "50px",
          }}
          onChange={(e) =>
            setDetails((prev) => ({
              ...prev,
              account_number: e.target.value,
            }))
          }
        />
      </div>

      <div>
        <CustomButton
          customStyles={{
            width: "100%",
            height: "40px",
            marginTop: "0",
            fontSize: "14px",
            borderRadius: "5px",
          }}
          loading={isVerifying || isAdding || isUpdating}
          onClick={() =>
            details.account_name.length
              ? update
                ? handleUpdateAcc()
                : handleAddAcc()
              : handleVerifyAcc()
          }
          disable={disable || isVerifying || isAdding || isUpdating}
          title={
            details.account_name.length
              ? update
                ? "Update"
                : "ADD"
              : "VERIFY ACCOUNT"
          }
        />
      </div>
    </div>
  );
};

import React from "react";
import "./FindJobs.css";
import AllJobsItem from "../allJobsItem/AllJobsItem";
import usePublicDivision from "../../hook/usePublicDivision";
import Slider from "react-slick";
import useIsMobile from "../../hook/useIsMobile";

const FindJobs = () => {
  const { divisonList } = usePublicDivision();
  const isMobile = useIsMobile();
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: isMobile ? 1 : 4,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
    arrows: false,
  };
  return (
    <div className="findJobs-container" data-aos="zoom-in">
      <h3>
        Search through Healthcare professional <br /> Divisions for Available
        On-demand vacancies near you.
      </h3>

      <div>
        {divisonList.length ? (
          <Slider {...settings} className="findJobs-tabs">
            {divisonList?.map(({ label, value }) => (
              <div key={value} className="jobs-tabs">
                <span>{label}</span>
              </div>
            ))}
          </Slider>
        ) : null}
      </div>

      <div>
        <AllJobsItem />
      </div>
    </div>
  );
};

export default FindJobs;

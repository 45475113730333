import React, { useState } from "react";
import "./Login.css";
import Logo from "../../../assets/fmlogo-login.png";
import Doctor from "../../../assets/doc1.png";
import { Link } from "react-router-dom";
import Logo2 from "../../../assets/Group.png";
import CustomInput from "../signUp/CustomInput";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import useLogin from "../../../hook/useLogin";
import CustomButton from "../../../components/customButton/CustomButton";

const Login = () => {
  const [passwordEye, setPasswordEye] = useState(false);

  const {
    values,
    handleChange,
    errors,
    touched,
    handleSubmit,
    handleBlur,
    isValid,
    loading,
  } = useLogin();

  const formSubmit = (e) => {
    e.preventDefault();
    handleSubmit();
  };

  const styles = {
    eye: {
      position: "absolute",
      top: "9.5px",
      right: "10px",
      fontSize: "22px",
      cursor: "pointer",
    },
  };

  return (
    <div className="login-container">
      <div className="login-left">
        <div className="login-left-content">
          <Link to="/">
            <div className="logo">
              <img src={Logo} alt="logo" />
            </div>
          </Link>

          <div className="login-left-text">
            <h3>Facilitating uninterrupted healthcare services.</h3>
          </div>
          <div className="login-left-image">
            <img src={Doctor} alt="" />
          </div>
        </div>
      </div>
      <div className="login-right">
        <div className="login-right-body">
          <div className="login-button-box">
            <Link to="/">
              <img src={Logo2} alt="logo" />
            </Link>
            <Link className="login-btn-link" to="/register">
              <CustomButton
                customStyles={{
                  width: "85px",
                  height: "40px",
                  marginTop: "0",
                  fontSize: "15px",
                  borderRadius: "5px",
                }}
                title="Sign Up"
              />
            </Link>
          </div>

          <div className="login-right-content">
            <form onSubmit={formSubmit}>
              <div className="form-label">
                <CustomInput
                  id="email"
                  label="Email address"
                  placeholder="Enter email address"
                  type="email"
                  value={values.email}
                  onChange={handleChange("email")}
                  touched={touched.email}
                  error={errors.email}
                  onBlur={(e) => handleBlur(e)}
                />
                <CustomInput
                  id="password"
                  label="Password"
                  placeholder="Password"
                  type={passwordEye ? "text" : "password"}
                  value={values.password}
                  onChange={handleChange("password")}
                  touched={touched.password}
                  error={errors.password}
                  onBlur={(e) => handleBlur(e)}
                  endIcon={
                    passwordEye ? (
                      <AiOutlineEye
                        style={styles.eye}
                        onClick={() => setPasswordEye(!passwordEye)}
                      />
                    ) : (
                      <AiOutlineEyeInvisible
                        style={styles.eye}
                        onClick={() => setPasswordEye(!passwordEye)}
                      />
                    )
                  }
                />
              </div>
              <Link to="/forgetpassword">
                <p className="forgot-password">forgot password?</p>
              </Link>

              <CustomButton
                disable={!isValid}
                type="submit"
                title="Login"
                loading={loading}
              />

              <h5 className="account">
                Don't have an account?{" "}
                <Link to="/register">
                  <span className="join">Join Now</span>
                </Link>{" "}
              </h5>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
